import React from 'react';
import * as styles from './404.module.css';

const NotFoundPage = (): React.ReactElement => {
  return (
    <div className={styles.container}>
      <h1>Oops! That page can`t be found.</h1>
      <a href="/">Back to Home page</a>
    </div>
  );
};

export default NotFoundPage;
